import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "pretitle": "Inspiration",
  "title": "Amazing Fan Art for The Force Awakens",
  "date": "2014-12-05T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.36363636363636%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAXiUr0Jj/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIDEQASMTP/2gAIAQEAAQUCMNkZGlJsQqdm9P/EABgRAAIDAAAAAAAAAAAAAAAAAAABAhMh/9oACAEDAQE/AVDCpH//xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIRIf/aAAgBAgEBPwFy0s//xAAdEAACAQQDAAAAAAAAAAAAAAAAAQIREiGBEDFx/9oACAEBAAY/ApSxjody0K6mxUYl5x//xAAaEAADAAMBAAAAAAAAAAAAAAAAASERMUGx/9oACAEBAAE/IX1UXxGmTdqIQzPwY5wJ5glH/9oADAMBAAIAAwAAABAT/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QzZDG3//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QfqGm3//EABoQAQEAAwEBAAAAAAAAAAAAAAERACFBMcH/2gAIAQEAAT8QlRSy2BtJ9wiUJ6DZJbvItmyhStF7PMHoBR4PXABdiO+YS6Vhn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "phil noto tfa fan art",
          "title": "phil noto tfa fan art",
          "src": "/static/683ec964970b634c3503e5d38a40605a/ddced/phil-noto-tfa-fan-art.jpg",
          "srcSet": ["/static/683ec964970b634c3503e5d38a40605a/35f54/phil-noto-tfa-fan-art.jpg 275w", "/static/683ec964970b634c3503e5d38a40605a/d7854/phil-noto-tfa-fan-art.jpg 550w", "/static/683ec964970b634c3503e5d38a40605a/ddced/phil-noto-tfa-fan-art.jpg 1100w", "/static/683ec964970b634c3503e5d38a40605a/63813/phil-noto-tfa-fan-art.jpg 1153w"],
          "sizes": "(max-width: 1100px) 100vw, 1100px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`People have already started making awesome fan art for the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Star_Wars:_The_Force_Awakens"
      }}>{`new Star Wars`}</a>{`. It makes you think just how much Star Wars is a huge part of our culture. The illustrator `}<a parentName="p" {...{
        "href": "https://www.philnoto.com/Star-Wars-cover-art"
      }}>{`Phil Notto`}</a>{` did an amazing job at creating this illustration with just the few images we’ve seen from the teaser.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      